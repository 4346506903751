import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Box, Button, Grid, CircularProgress, Chip, Divider } from '@mui/material'
import TextGenerator from '../../components/TextGenerator'
import { Wrapper } from '../../components/Wrapper'
import Search from '../../components/Search'
import SelectSource from '../../components/SelectSource'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import { openai, sourceVariant, variantMazhab, variantModernSheikhs, variantQuran } from './DashboardHelper'
import { GptVersion } from '../../types'

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>,
) {
    return (
        <Slide
            direction="up"
            ref={ref}
            {...props}
        />
    )
})

export const DashboardPage: React.FC = () => {
    const [generatedText, setGeneratedText] = useState<any>(null)
    const [isLoading, setIsLoading] = useState(false)
    const [searchTopic, setSearchTopic] = useState(
        process.env.NODE_ENV === 'development' ? 'портит ли омовение прикосновение к жене?' : '',
    )
    const [lengthOfText, setLengthOfText] = useState(3000)
    const [isError, setIsError] = useState(false)
    const [temperature, setTemperature] = useState(0.3)
    const user = JSON.parse(localStorage.getItem('user') || '{}')
    const token = localStorage.getItem('token')
    const savedSource = localStorage.getItem('source')
    const [source, setSource] = useState(JSON.parse(localStorage.getItem('source') || '{}'))
    const [open, setOpen] = useState(false)
    const [selectTheme, setSelectTheme] = useState(JSON.parse(savedSource!) ?? sourceVariant[0])


    useEffect(() => {
        if (selectTheme?.name === sourceVariant[0].name) {
            localStorage.setItem('source', JSON.stringify(sourceVariant[0]))
            setSource(variantMazhab)
        } else if (selectTheme?.name === sourceVariant[1].name) {
            localStorage.setItem('source', JSON.stringify(sourceVariant[1]))
            setSource(variantModernSheikhs)
        } else if (selectTheme?.name === sourceVariant[2].name) {
            localStorage.setItem('source', JSON.stringify(sourceVariant[2]))
            setSource(variantQuran)
        }
    }, [selectTheme])



    // Generate text
    const generateText = async () => {
        if (!searchTopic) {
            toast('Пожалуйста, введите тему поиска', { type: 'error' })
            setIsError(true)
            return
        }
        setIsLoading(true)
        try {
            const textContent = prepareTextForChatGpt(searchTopic)

            const response = await openai.chat.completions.create({
                messages: [{ role: 'user', content: textContent }],
                max_tokens: lengthOfText,
                temperature: temperature,
                model: GptVersion.four,
                response_format: { type: 'json_object' },
            })

            if (response.choices && response.choices.length > 0) {
                const textContent = response.choices[0].message.content
                setGeneratedText(JSON.parse(textContent!))
            } else {
                setGeneratedText({ error: 'Нет данных для отображения' })
            }
        } catch (error) {
            console.error('Error generating text:', error)
            setGeneratedText({ error: 'Ошибка при генерации текста' })
        } finally {
            setIsLoading(false)
            setIsError(false)
        }
    }

    // Generate prompt
    const prepareTextForChatGpt = (searchTopic: string) => {
        return `${source}
    
        Теперь ответь на следующий вопрос, соблюдая все указанные правила: ${searchTopic}`
    }

    const handleClear = () => {
        setSearchTopic('')
        setIsError(false)
    }

    return (
        <Wrapper>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setOpen(false)}
                aria-describedby="alert-dialog-slide-description"
                fullWidth
            >
                <DialogTitle sx={{ pb: 1 }}>{'Фильтр'}</DialogTitle>
                <Divider sx={{ mb: 3 }}>Выберите источник поиска</Divider>
                <Box sx={{ px: 3 }}>
                    <SelectSource
                        data={sourceVariant}
                        value={selectTheme.id}
                        onChange={(e: any) => {
                            const selectedSource = sourceVariant.find((item) => item.id === e.target.value)
                            setSelectTheme(selectedSource!)
                        }}
                        defaultValue={sourceVariant[0].id}
                        label="Источник поиска"
                    />
                </Box>
                <DialogActions>
                    <Button
                        onClick={() => setOpen(false)}
                    >
                        Cancel
                    </Button>
                    <Button onClick={() => setOpen(false)}>Select</Button>
                </DialogActions>
            </Dialog>

            <Grid
                container
                justifyContent={'center'}
            >
                <Grid
                    item
                    xs={12}
                    md={10}
                    mt={2}
                    sx={{
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Box>
                        <Chip
                            label={selectTheme.name}
                            color="primary"
                            variant="outlined"
                            sx={{ opacity: 0.8 }}
                        />
                    </Box>
                    <Button
                        onClick={() => setOpen(true)}
                        endIcon={<FilterAltIcon />}
                        variant={'outlined'}
                    >
                        Фильтр
                    </Button>
                </Grid>
                <Grid
                    container
                    justifyContent={'center'}
                    spacing={2}
                    alignItems={'center'}
                >
                    {/* Search */}
                    <Grid
                        item
                        xs={12}
                        md={8}
                        my={2}
                    >
                        <Search
                            searchTopic={searchTopic}
                            setSearchTopic={setSearchTopic}
                            handleClear={handleClear}
                            isLoading={isLoading}
                            isError={isError}
                            generateText={generateText}
                            setGeneratedText={setGeneratedText}
                            setIsError={setIsError}
                        />
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        md={2}
                        my={{ xs: 0, md: 2 }}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Box sx={{ width: 250 }}>
                            <Button
                                variant="outlined"
                                onClick={generateText}
                                fullWidth
                                disabled={isLoading || searchTopic?.length < 5}
                            >
                                {isLoading ? 'Делаем запрос...' : 'Задать вопрос'}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>

                <Grid
                    item
                    xs={12}
                    my={2}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        minHeight: '300px',
                    }}
                >
                    {isLoading ? <CircularProgress /> : <TextGenerator generatedText={generatedText} />}
                </Grid>
            </Grid>
        </Wrapper>
    )
}
