import * as React from 'react';
import * as Yup from 'yup';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import { User } from '../types';
import { ROUTES } from '../utils/consts';
import { CustomCopyright } from '../utils/CustomCopyright';
import { toast } from 'react-toastify';
import { URLs } from '../api/urls';

const defaultTheme = createTheme();

const RegisterPageSchema = Yup.object().shape({
    firstName: Yup.string()
        .min(1, 'First Name is required!')
        .max(50, 'Too Long!')
        .required('First name is required'),
    lastName: Yup.string()
        .min(1, 'Last Name is required!')
        .max(50, 'Too Long!')
        .required('Last name is required'),
    phone: Yup.number().required('Phone field is required').typeError('Phone must be a number'),
    email: Yup.string().email('Invalid email').required('Required field'),
    password: Yup.string().min(5, 'Too Short!').required('Required field'),
    age: Yup.number().min(18, 'Age must be greater than 18').required('Required field'),
});

export const RegisterPage = () => {
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const navigate = useNavigate();

    const formik = useFormik<User>({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            age: 18,
            phone: '',
        },
        onSubmit: async (values) => {
            // console.log(values);

            try {

                const response = await fetch(URLs.register, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',

                    },
                    body: JSON.stringify(values)
                })

                const data = await response.json()

                if (response.ok) {
                    // console.log('data', data);
                    formik.resetForm();
                    toast.success('Account created successfully!');
                    navigate(`/${ROUTES.login}`);

                }

                if (!response.ok) {
                    // console.log('data', data);
                    toast.error(data?.message);
                }

            } catch (error: any) {
                console.log('error', error);
                toast.error(error?.message);
            }


        },
        validationSchema: RegisterPageSchema,
    });

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid container justifyContent={'center'} flexDirection={'column'} height={'100vh'} sx={{ width: { xs: '90%', md: '400px' }, mx: 'auto' }}>
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign up
                    </Typography>
                    <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ my: 2 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="firstName"
                            label="First Name"
                            name="firstName"
                            autoComplete="given-name"
                            autoFocus
                            value={formik.values.firstName}
                            onChange={formik.handleChange}
                            helperText={formik.errors.firstName}
                            error={!!formik.errors.firstName}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="lastName"
                            label="Last Name"
                            name="lastName"
                            autoComplete="family-name"
                            value={formik.values.lastName}
                            onChange={formik.handleChange}
                            helperText={formik.errors.lastName}
                            error={!!formik.errors.lastName}
                        />
                        <TextField
                            margin="normal"
                            required
                            helperText={formik.errors.email}
                            error={!!formik.errors.email}
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}

                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="phone"
                            label="Phone Number"
                            name="phone"
                            autoComplete="tel"
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            helperText={formik.errors.phone}
                            error={!!formik.errors.phone}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="age"
                            label="Age"
                            name="age"
                            type="number"
                            autoComplete="age"
                            InputProps={{ inputProps: { min: 5, max: 100 } }}
                            value={formik.values.age}
                            onChange={formik.handleChange}
                            helperText={formik.errors.age}
                            error={!!formik.errors.age}
                        />
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                            <OutlinedInput
                                fullWidth
                                id="outlined-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                value={formik.values.password}
                                onChange={e => formik.setFieldValue('password', e.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                            />
                        </FormControl>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={!formik.isValid || formik.isSubmitting}
                        >
                            Sign Up
                        </Button>
                        <Grid container>
                            {/* <Grid item xs>
                                <Link href="#" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid> */}
                            <Grid item>
                                <Link href="/login" variant="body2">
                                    {"Already have an account? Sign In"}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                    <CustomCopyright sx={{ mt: 4, mb: 4 }} />
                </Box>
            </Grid>
        </ThemeProvider>
    );
};
