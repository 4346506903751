import { HomePage } from '../pages/HomePage';
import { AboutPage } from '../pages/AboutPage';
import { createBrowserRouter } from "react-router-dom";
import { SettingsPage } from '../pages/SettingsPage';
import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';
import { LoginPage } from '../pages/LoginPage';
import { RegisterPage } from '../pages/RegisterPage';
import { DashboardPage } from '../pages/Dashboard/DashboardPage';
import ErrorPage from '../pages/ErrorPage';
import FeedbackPage from '../pages/FeedbackPage';

export const router = createBrowserRouter([
    {
        path: "/",
        element: <PublicRoute element={<HomePage />} />,
    },
    {
        path: "/about",
        element: (
            <PublicRoute element={<AboutPage />} />
        ),
    },
    {
        path: "/settings",
        element: (
            <ProtectedRoute element={<SettingsPage />} />
        ),
    },
    {
        path: '/login',
        element: (
            <PublicRoute element={<LoginPage />} />
        ),
    },
    {
        path: '/register',
        element: (
            <PublicRoute element={<RegisterPage />} />
        ),
    },
    {
        path: '/dashboard',
        element: (
            <ProtectedRoute element={<DashboardPage />} />
        ),
    },
    {
        path: '*',
        element: <ErrorPage />
    },
    {
        path: '/feedback',
        element: <ProtectedRoute element={<FeedbackPage />} />
    }
]);

