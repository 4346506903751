import { Box, Grid, Typography, Tooltip, IconButton, Divider, Alert } from '@mui/material'
import PrintIcon from '@mui/icons-material/Print'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

interface TextGeneratorProps {
    generatedText: any
}

const TextGenerator: React.FC<TextGeneratorProps> = ({ generatedText }) => {
    const handlePrint = () => {
        window.print()
    }
    return (
        <>
            {generatedText ? (
                <Box id="printableArea">
                    <Grid
                        item
                        xs={12}
                        md={10}
                        justifyContent={'center'}
                        mx="auto"
                        my={8}
                    >
                        <Box
                            display="flex"
                            justifyContent="center"
                        >
                            <Typography
                                variant="h4"
                                align="center"
                                gutterBottom
                                style={{
                                    margin: '20px 0',
                                    color: '#3f51b5',
                                    fontWeight: 'bold',
                                    position: 'relative',
                                }}
                            >
                                Ответы на вопросы
                                <Tooltip
                                    title={
                                        <span style={{ fontSize: '16px' }}>
                                            Все ответы беруться из корана и хадисов, но все равно могут быть допущенны
                                            ошибки или неточности, так как они обрабатываются искусственным интеллектом.
                                            Для более точной информации обращайтесь к знающим людям.
                                        </span>
                                    }
                                    placement="top"
                                >
                                    <IconButton
                                        size="small"
                                        style={{
                                            marginLeft: '10px',
                                            verticalAlign: 'middle',
                                            position: 'absolute',
                                            right: -33,
                                            top: -5,
                                        }}
                                    >
                                        <HelpOutlineIcon fontSize={'small'} />
                                    </IconButton>
                                </Tooltip>
                            </Typography>
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            mb={2}
                        >
                            {generatedText?.other ? null : (
                                <PrintIcon
                                    onClick={handlePrint}
                                    sx={{ cursor: 'pointer', color: '#3f51b5' }}
                                />
                            )}
                        </Box>
                        <Divider />
                        <Box my={4}>
                            {generatedText?.answers?.map((item: any, index: number) => (
                                <Box key={index}>
                                    <Alert
                                        variant="filled"
                                        severity="success"
                                    >
                                        {item?.name}
                                    </Alert>
                                    <p className="text quran">{item?.answer}</p>
                                </Box>
                            ))}

                            {generatedText?.other && <Box className="text">{generatedText?.other}</Box>}
                        </Box>
                        <Divider />
                    </Grid>
                </Box>
            ) : null}
        </>
    )
}

export default TextGenerator
