import { AuthState, User } from '../../types'

type Action = { type: 'LOGIN'; payload: User } | { type: 'LOGOUT' } | { type: 'RESTORE_AUTH'; payload: AuthState }

export const authReducer = (state: AuthState, action: Action): AuthState => {
    switch (action.type) {
        case 'LOGIN':
            return { isAuthenticated: true, user: action.payload }
        case 'LOGOUT':
            return { isAuthenticated: false, user: null }
        case 'RESTORE_AUTH':
            return action.payload
        default:
            return state
    }
}
