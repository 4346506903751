import React from 'react'
import { Box, Container } from '@mui/material'
import ResponsiveAppBar from '../AppBar'
import Footer from '../Footer'

export const Wrapper = ({ children }: React.PropsWithChildren) => {
    return (
        <>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '80vh'
            }}

                className='wrapper'

            >
                <ResponsiveAppBar />
                <Container sx={{ my: 4 }}>
                    {children}
                </Container>
            </div>
            <Footer />
        </>
    )
}
