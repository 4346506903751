import OpenAI from "openai"

export const mazhabs = ['Ханафитский мазхаб', 'Маликитский мазхаб', 'Шафиитский мазхаб', 'Ханбалитский мазхаб']

export const modernAlims = [
    'Са́лих ибн Фауза́н ибн Абдулла́х аль-Фауза́н',
    'Шейх Абдуль-Азиз ибн Баз',
    'Абу́ Абдулла́х Муха́ммад ибн Са́лих аль-Усейми́н',
    'Шейх Халид-аль Фулейдж',
    'Таки́ ад-Дин Ибн Тайми́я',
]

enum sourceNames {
    '4 Мазхаба' = '4 Мазхаба',
    'Современные шейхи' = 'Современные шейхи',
    'Коран и Хадисы' = 'Коран и Хадисы',
}

export const sourceVariant = [
    {
        id: 1,
        name: sourceNames['4 Мазхаба'],
    },
    {
        id: 2,
        name: sourceNames['Современные шейхи'],
    },
    {
        id: 3,
        name: sourceNames['Коран и Хадисы'],
    },
]

export const variantMazhab = `
    При ответе соблюдай следующие условия:
        1. Приведи довод мазхабов на данный вопрос - используй только эти мазхабы: ${mazhabs.join(', ')}.,
        2. При упоминании Пророка Мухаммада всегда в круглых скобках пиши сокращенно на арабском (ﷺ).
        3. Не используй никаких других источников кроме вышеупомянутых.
        4. Разбей ответы по объектам в формате JSON: answers: { name: 'имя мазхаба', answer: 'ответ мазхаба' },
        5. Если нету ответа то верни объект other: "Ответ не найден".
        6. Верни ответ в формате JSON.
`

export const variantModernSheikhs = `
    При ответе соблюдай следующие условия:
    1. Приведи доводы только от этих шейхов: ${modernAlims.join(', ')},
    2. При упоминании Пророка Мухаммада всегда в круглых скобках пиши сокращенно на арабском (ﷺ),
    3. Не используй никаких других источников кроме вышеупомянутых,
    4. Разбей ответы по объектам в формате JSON: answers: { name: 'имя шейха', answer: 'ответ шейха' },
    5. Если нету ответа, то верни объект { other: 'Ответ не найден' },
    6. Верни ответ в формате JSON.
`

export const variantQuran = `
    При ответе соблюдай следующие условия:
        1. Приведи до 5 аятов из Корана, в конце каждого аята укажи данные на аят.
        2. Приведи до 5 хадисов из достоверного сборника хадисов брать хадисы только из - аль-Бухари и Мухйиддин ан-Навави, в конце каждого хадиса укажи данные на хадис.
        3. При упоминании Пророка Мухаммада всегда в круглых скобках пиши сокращенно на арабском (ﷺ).
        4. Не используй никаких других источников кроме вышеупомянутых.
        5. Разбей ответы по объектам: аят из Корана в объект answers: { name: "quran", answer: "текст корана"}, { name: "hadis", answer: "текст хадиса" },
        6. Если нету ответа, то верни объект { other: "Ответ не найден" },
        7. Верни ответ в формате JSON.
`
// openai setup
export const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
})