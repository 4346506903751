import React, { createContext, useReducer, ReactNode, useContext, useEffect } from 'react';
import { AuthState, User } from '../types';
import { authReducer } from './reducer/authReducer';

interface AuthContextProps {
    state: AuthState;
    login: (user: User, rememberMe: boolean) => void;
    logout: () => void;
}

const initialState: AuthState = {
    isAuthenticated: false,
    user: null,
};

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
    const [state, dispatch] = useReducer(authReducer, initialState);

    useEffect(() => {
        const storedState = localStorage.getItem('authState');
        if (storedState) {
            dispatch({ type: 'RESTORE_AUTH', payload: JSON.parse(storedState) });
        }
    }, []);

    const login = (user: User, rememberMe: boolean) => {
        const newState = { isAuthenticated: true };
        if (rememberMe) {
            localStorage.setItem('authState', JSON.stringify(newState));
            localStorage.setItem('user', JSON.stringify(user));
        } else {
            sessionStorage.setItem('authState', JSON.stringify(newState));
            localStorage.setItem('user', JSON.stringify(user));
        }

        dispatch({ type: 'LOGIN', payload: user });


    };

    const logout = () => {
        // localStorage.removeItem('authState');
        // localStorage.removeItem('user');
        // localStorage.removeItem('token');
        localStorage.clear();
        dispatch({ type: 'LOGOUT' });
    };

    return (
        <AuthContext.Provider value={{ state, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = (): AuthContextProps => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
