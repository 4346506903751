import { ROUTES } from '../utils/consts'

// const domainName = 'https://islam-ai.herokuapp.com/api'
const domainName = 'https://islam-ai-backend.vercel.app/api'
const devPort = `http://localhost:${process.env.PORT || 4000}/api`

const BASE_URL = process.env.NODE_ENV === 'production' ? domainName : devPort

export const URLs = Object.freeze({
    register: `${BASE_URL}/${ROUTES.register}`,
    login: `${BASE_URL}/login`,
    users: `${BASE_URL}/users`,
    user: `${BASE_URL}/user`,
})
