export interface RouteConfig {
    path: string
    element: React.ReactNode
}

export enum LanguageLevelEnum {
    A1 = 'A1 - Beginner',
    A2 = 'A2 - Elementary',
    B1 = 'B1 - Intermediate',
    B2 = 'B2 - Upper Intermediate',
    C1 = 'C1 - Advanced',
    C2 = 'C2 - Proficient',
}

export enum TopicEnum {
    PROGRAMMING = 'Programming',
    HISTORY = 'History',
    GEOGRAPHY = 'Geography',
    WORK = 'Work',
    TRAVEL = 'Travel',
    TALK = 'Talk',
    HEALTH = 'Health',
    TRUCKING = 'Trucking',
    CARS = 'Cars',
    CLOTHING = 'Clothing',
    FOOD = 'Food',
    BOOKS = 'Books',
    MOVIES = 'Movies',
}

export interface LanguageLevel {
    level: LanguageLevelEnum
    topic: TopicEnum
    vocabulary: string[]
}

export interface AuthState {
    isAuthenticated: boolean
    user: User | null
}

export type AuthAction = { type: 'LOGIN'; payload: User } | { type: 'LOGOUT' }

export enum AuthRouteEnum {
    LOGIN = 'login',
    REGISTER = 'register',
    SETTINGS = 'settings',
    HOME = '/',
    ABOUT = 'about',
    DASHBOARD = 'dashboard',
}

export interface User {
    id?: string
    email: string
    password: string
    age?: number
    phone: string
    firstName: string
    lastName: string
    token?: string
}

export type GeneratedText = {
    quran?: string | string[]
    hadis?: string | string[]
    error?: string | null
}

export enum MazhabTypeEnum {
    Shafi = 'Шафиитский Мазхаб',
    Hanafi = 'Ханафиитский Мазхаб',
    Maliki = 'Маликий Мазхаб',
    Hanbali = 'Ханабалий Мазхаб',
    allMazhab = 'Все Мазхабы',
}

export enum GptVersion {
    three = 'gpt-3.5-turbo',
    four = 'gpt-4o',
}
