import { TextField, InputAdornment, IconButton } from '@mui/material';
import React from 'react'
import ClearIcon from '@mui/icons-material/Clear'


export default function Search({
    searchTopic,
    setSearchTopic,
    handleClear,
    isLoading,
    isError,
    generateText,
    setGeneratedText,
    setIsError,
}: any) {
    return (
        <TextField
            error={isError}
            fullWidth
            size="small"
            label="Напишите ваш вопрос здесь"
            value={searchTopic}
            onChange={(e) => {
                setSearchTopic(e.target.value)
                setIsError(false)
            }}
            InputProps={{
                endAdornment: searchTopic && (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="clear input"
                            onClick={handleClear}
                            edge="end"
                        >
                            <ClearIcon />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            onKeyDown={(e) => {
                if (e.key === 'Enter' && searchTopic?.length > 5 && !isLoading) {
                    e.preventDefault();
                    generateText();
                }

                // clear input on escape key
                if (e.key === 'Escape') {
                    setSearchTopic('');
                    setGeneratedText(null);
                }
            }}
        />
    )
}
