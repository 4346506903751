import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import { CustomCopyright } from '../utils/CustomCopyright';
import { ROUTES } from '../utils/consts';
import { useFormik } from 'formik';
import { URLs } from '../api/urls';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import { useAuth } from '../context/AuthContext';



const defaultTheme = createTheme();

const LoginPageSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email Required field'),
    password: Yup.string().min(5, 'Too Short!').required('Password Required field'),
});

export const LoginPage = () => {
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const [isRemember, setIsRemember] = React.useState(false)
    const { login } = useAuth();



    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        onSubmit: async (values) => {
            // console.log(values);
            try {

                const response = await fetch(URLs.login, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',

                    },
                    body: JSON.stringify(values)
                })

                const data = await response.json()

                if (response.ok) {
                    // console.log('data', data);
                    formik.resetForm();
                    toast.success('Login success!');
                    localStorage.setItem('token', data?.token);
                    login(data?.user, isRemember);
                }

                if (!response.ok) {
                    // console.log('data', data);
                    toast.error(data?.message);
                }

            } catch (error: any) {
                console.log('error', error);
                toast.error(error?.message);
            }
        },
        validationSchema: LoginPageSchema
    });



    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid container
                justifyContent={'center'}
                flexDirection={'column'} height={'100vh'}
            >
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        px: {
                            xs: 2,
                            md: 0
                        },

                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Вход
                    </Typography>
                    <Box component="form" noValidate sx={{ my: 2 }}>
                        <TextField
                            margin="normal"
                            required
                            helperText={formik.errors.email}
                            error={!!formik.errors.email}
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            sx={{ mb: 3 }}

                        />
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                            <OutlinedInput
                                fullWidth
                                id="outlined-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                value={formik.values.password}
                                onChange={e => formik.setFieldValue('password', e.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                                name="password"
                                autoComplete="password"
                                required
                                error={!!formik.errors.password}
                            />
                            {!!formik.errors.password && (
                                <FormHelperText error id="accountId-error">
                                    {formik.errors.password}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <Box>
                            <FormControlLabel
                                control={
                                    <Checkbox value={isRemember}
                                        onChange={() => setIsRemember(!isRemember)}
                                        color="primary" />
                                }
                                label="Remember me"
                            />
                        </Box>
                        <LoadingButton
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={() => formik.handleSubmit()}
                            disabled={!formik.isValid || formik.isSubmitting}
                            loading={formik.isSubmitting}
                        >
                            Sign In
                        </LoadingButton>
                        <Grid container>
                            {/* <Grid item xs>
                                <Link href="#" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid> */}
                            <Grid item>
                                <Link href={ROUTES.register} variant="body2">
                                    {/* {"Don't have an account? Sign Up"} */}
                                    Все еще нет аккаунта? Зарегистрироваться
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <CustomCopyright sx={{ mt: 4, mb: 4 }} />
            </Grid>
        </ThemeProvider >
    );
}