import React from 'react'
import { Wrapper } from '../components/Wrapper'
import { Typography } from '@mui/material'

export default function FeedbackPage() {
    return (
        <Wrapper>
            <h1>Обратная связь</h1>
            <Typography variant={'h6'}>webdevelope2017@gmail.com</Typography>
        </Wrapper>
    )
}
