import { AuthRouteEnum } from '../types'

export const CONSTS = Object.freeze({
    seo: {
        name: 'WeAlim',
        title: 'WeAlim - исламские ответы на основе искусственного интеллекта',
        description: 'WeAlim AI -  with WeAlim you get all answers on Islam with AI',
        keywords: 'WeAlim, islam, AI, Islam-ai',
        author: 'Mario Magomedov',
        copyright: 'WeAlim',
        website: 'https://wealim.com',
        robots: 'index, follow',
        viewport: 'width=device-width, initial-scale=1.0',
        charset: 'UTF-8',
        icon: 'https://wealim.com/favicon.ico',
        type: 'website',
        url: 'https://wealim.com',
        og_title: 'WeAlim - WeAlim AI app',
        og_description: 'WeAlim - WeAlim AI app',
        og_image: 'https://wealim.com/favicon.ico',
    },
})

export const ROUTES = Object.freeze({
    home: AuthRouteEnum.HOME,
    dashboard: AuthRouteEnum.DASHBOARD,
    about: AuthRouteEnum.ABOUT,
    settings: AuthRouteEnum.SETTINGS,
    login: AuthRouteEnum.LOGIN,
    register: AuthRouteEnum.REGISTER,
})
