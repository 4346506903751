import { Box, Divider, Grid } from '@mui/material'
import React from 'react'
import { CONSTS } from '../../utils/consts'
import { CustomCopyright } from '../../utils/CustomCopyright'

export default function Footer() {
    return (
        <>
            <Grid container sx={{
                bgcolor: '#1976d2',
                color: '#fff',
                minHeight: 250
            }}>
                <Grid item xs={12} md={4} sx={{
                    p: 5,
                    display: 'flex',
                }}>
                    {CONSTS.seo.name} - это исламский сайт, вопрос - ответ, при использовании современных технологий, искуственного интеллекта.
                </Grid>
                <Grid item xs={12} md={4} sx={{
                    p: 5,
                    color: 'white',
                    display: 'flex',
                }}>

                </Grid>
                <Grid item xs={12} md={4} sx={{
                    p: 5,
                    color: 'white',
                }}>
                    {/* WeKnow.com - это исламский сайт, основанный на современных технологиях, искуственного интеллекта. */}
                </Grid>


            </Grid>
            <Divider />
            <Box sx={{
                bgcolor: '#1976d2',
                color: '#fff',
                height: 30,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <CustomCopyright sx={{
                    color: 'white',
                }} />
            </Box>
        </>
    )
}
