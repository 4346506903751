// PublicRoute.tsx
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { ROUTES } from '../utils/consts';

interface PublicRouteProps {
    element: React.ReactElement;
}

const PublicRoute: React.FC<PublicRouteProps> = ({ element }) => {
    const { state } = useAuth();

    if (state.isAuthenticated) {
        return <Navigate to={`/${ROUTES.dashboard}`} replace />;
    }

    return element;
};

export default PublicRoute;
