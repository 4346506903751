import React from 'react'
import { Grid, Typography } from '@mui/material'
import { Wrapper } from '../components/Wrapper'



export const HomePage: React.FC = () => {
    return (
        <Wrapper>
            <Grid
                container
                justifyContent={'center'}
                spacing={2}
            >
                <Typography variant={'h4'}>Home page </Typography>
            </Grid>
        </Wrapper>
    )
}
