import { FormControl, InputLabel, Select, MenuItem } from '@mui/material'

export default function SelectSource({
    disabled = false,
    onChange,
    value,
    id = 'mazhab',
    label = 'label',
    defaultValue,
    data
}: any) {
    return (
        <FormControl fullWidth disabled={disabled}>
            <InputLabel id="mazhab">{label}</InputLabel>
            <Select
                labelId={id}
                id={id}
                value={value}
                label={label}
                onChange={onChange}
                defaultValue={defaultValue}
            >
                {data.map((item: any) => (
                    <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
