import { Typography } from "@mui/material";
import { CONSTS } from "./consts";
import Link from '@mui/material/Link';

export const CustomCopyright = (props: any) => {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href={CONSTS.seo.website}>
                {CONSTS.seo.name}
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}
