import React from 'react';
import {
    RouterProvider,
} from "react-router-dom";
import ReactDOM from 'react-dom/client';
import './index.css';
import { router } from './router';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider } from './context/AuthContext';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
        <AuthProvider>
            <RouterProvider router={router} />
            <ToastContainer position="top-right" />
        </AuthProvider>
  </React.StrictMode>
);

