import { Button, Box } from "@mui/material";

export default function ErrorPage() {
    return (
        <Box id="error-page" sx={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
        }}>
            <h1>Oops!</h1>
            <p>Похоже такой страницы не существует.</p>
            <p>
                <Button variant="outlined" href="/">На главную</Button>
            </p>
        </Box>
    );
}