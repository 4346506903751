import { useFormik } from 'formik';
import { Wrapper } from '../components/Wrapper'
import { Box, Button, CircularProgress, Divider, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material'
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { MazhabTypeEnum, User } from '../types';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect, useState } from 'react';
import { URLs } from '../api/urls';
import { Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

export const SettingsPage = () => {
    const [isEdit, setIsEdit] = useState(true)
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const token = localStorage.getItem('token');
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        fetchUser()
    }, [])

    // get user
    const fetchUser = async () => {
        try {
            setIsLoading(true)
            const response = await fetch(`${URLs.user}/${user?._id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            })

            const data = await response.json()
            // console.log("🚀 ~ fetchUser ~ data:", data)
            if (response.ok) {
                formik.setValues({
                    ...data?.user
                })
            } else {
                toast.error(data?.message);
            }

        } catch (error: any) {
            console.log('error', error);
            toast.error(error?.message);
        } finally {
            setIsLoading(false)
        }
    }

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            age: '',
            phone: '',
            mazhab: MazhabTypeEnum.Hanafi,
            paid: false
        },
        onSubmit: async (values) => {
            console.log('values', values);
            try {

                setIsLoading(true)
                const response = await fetch(`${URLs.user}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify(values)
                })

                const data = await response.json()
                console.log("🚀 ~ fetchUser ~ data:", data)
                if (response.ok) {
                    toast.success('User updated successfully!');
                    setIsEdit(!isEdit)
                    localStorage.setItem('user', JSON.stringify(data?.user));
                } else {
                    toast.error(data?.message);
                }

            } catch (error: any) {
                console.log('error', error);
                toast.error(error?.message);
            } finally {
                setIsLoading(false)
            }


        },
    });

    const handleToggleEdit = () => {
        if (!isEdit) {
            formik.handleSubmit();
        }
        setIsEdit(false);
    };

    return (
        <Wrapper>
            <Typography variant="h1" textAlign="center" my={5} fontSize={40}>Настройки</Typography>
            <Divider sx={{ my: 5 }}>Настройки пользователя</Divider>
            <Box display="flex" justifyContent="flex-end">

                <LoadingButton
                    loading={isLoading}
                    onClick={handleToggleEdit}
                    variant="text"
                    sx={{ mb: 1, }}
                    startIcon={isEdit ? <EditIcon /> : <Save />}>
                    {isEdit ? 'Редактировать' : 'Сохранить'}
                </LoadingButton>
            </Box>
            {
                isLoading ?
                    <Box display="flex" justifyContent="center" alignItems={'center'} height={'40vh'}>
                        <CircularProgress />
                    </Box>
                    :
                    <Paper elevation={2} sx={{ p: 5 }}>
                        <form onSubmit={formik.handleSubmit}>

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        disabled={isEdit}
                                        sx={{ mb: 3 }}
                                        fullWidth
                                        label="Имя"
                                        id="firstName"
                                        name="firstName"
                                        value={formik.values.firstName}
                                        onChange={formik.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        disabled={isEdit} sx={{ mb: 3 }}
                                        fullWidth
                                        label="Фамилия"
                                        id="lastName" name="lastName"
                                        value={formik.values.lastName}
                                        onChange={formik.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        disabled={isEdit}
                                        sx={{ mb: 3 }}
                                        fullWidth
                                        label="Email"
                                        id="email"
                                        name="email"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        disabled={isEdit} sx={{ mb: 3 }}
                                        fullWidth
                                        label="Телефон"
                                        id="phone"
                                        name="phone"
                                        value={formik.values.phone}
                                        onChange={formik.handleChange}
                                        helperText={formik.errors.phone}
                                        error={!!formik.errors.phone}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth disabled={isEdit}>
                                        <InputLabel id="mazhab">Мой Мазхаб</InputLabel>
                                        <Select
                                            labelId="mazhab"
                                            id="mazhab"
                                            value={formik.values.mazhab}
                                            label="Мой Мазхаб"
                                            onChange={formik.handleChange}
                                            name="mazhab"
                                            defaultValue={MazhabTypeEnum.Shafi}
                                        >
                                            <MenuItem value={MazhabTypeEnum.Shafi}>{MazhabTypeEnum.Shafi}</MenuItem>
                                            <MenuItem value={MazhabTypeEnum.Hanafi}>{MazhabTypeEnum.Hanafi}</MenuItem>
                                            <MenuItem value={MazhabTypeEnum.Maliki}>{MazhabTypeEnum.Maliki}</MenuItem>
                                            <MenuItem value={MazhabTypeEnum.Hanbali}>{MazhabTypeEnum.Hanbali}</MenuItem>
                                            <MenuItem
                                                value={MazhabTypeEnum.allMazhab}
                                                disabled
                                            >
                                                {MazhabTypeEnum.allMazhab}
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        disabled={isEdit} sx={{ mb: 3 }}
                                        fullWidth
                                        type='number'
                                        label="Возраст"
                                        id="age" name="age"
                                        value={formik.values.age}
                                        onChange={formik.handleChange}
                                        inputProps={{ min: 10, max: 100 }}
                                    />
                                </Grid>
                            </Grid>
                        </form>
                    </Paper>
            }

        </Wrapper >
    )
}
