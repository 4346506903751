import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useAuth } from '../../context/AuthContext';
import { Link as RouterLink } from 'react-router-dom';
import PaidIcon from '@mui/icons-material/Paid';

const pages = [
    {
        name: 'Dashboard',
        path: '/dashboard'
    },
    {
        name: 'Settings',
        path: '/settings',
    },
    {
        name: 'Feedback',
        path: '/feedback',
    }

];

const authPages = [
    {
        name: 'Login',
        path: '/login'
    }, {
        name: 'Register',
        path: '/register',
    },
    {
        name: 'About',
        path: '/about'
    }
];

const settings = ['Logout'];
const drawerWidth = 240;

function ResponsiveAppBar() {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const { logout } = useAuth();
    const user = JSON.parse(localStorage.getItem('user') || '{}');


    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        logout()
        setAnchorElUser(null);
    };

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setDrawerOpen(open);
    };

    // sidebar menu
    const drawer = (
        <Box
            sx={{ width: drawerWidth }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                {
                    user?._id ?
                        pages.map((text, index) => (
                            <ListItem key={`afads-${index}`} disablePadding>
                                <ListItemButton component={RouterLink} to={`${text.path.toLowerCase()}`}>
                                    <ListItemText primary={text.name} />
                                </ListItemButton>
                            </ListItem>
                        ))

                        :
                        authPages.map((text, index) => (
                            <ListItem key={`afads2-${index}`} disablePadding>
                                <ListItemButton component={RouterLink} to={`${text.path.toLowerCase()}`}>
                                    <ListItemText primary={text.name} />
                                </ListItemButton>
                            </ListItem>
                        ))
                }
            </List>
        </Box>
    );

    return (
        <>
            <AppBar position="static">
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        {
                            user?._id &&
                            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                                <IconButton
                                    size="large"
                                    aria-label="open drawer"
                                    onClick={toggleDrawer(true)}
                                    color="inherit"

                                >
                                    <MenuIcon />
                                </IconButton>
                            </Box>
                        }
                        {/* <AdbIcon
                            sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}
                        /> */}
                        {/* <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="#app-bar-with-responsive-menu"
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            LOGO
                        </Typography> */}

                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="open drawer"
                                onClick={toggleDrawer(true)}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                        </Box>
                        <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
                        <Typography
                            variant="h5"
                            noWrap
                            component={'a'}
                            href="/dashboard"
                            sx={{
                                mr: 2,
                                display: { xs: 'flex', md: 'none' },
                                flexGrow: 1,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            We Alim - AI
                        </Typography>

                        <Box sx={{
                            flexGrow: 1,
                            display: { xs: 'none', md: 'flex' },
                            alignItems: 'center'
                        }}>
                            {user?._id &&
                                <Typography variant="h6">
                                    {user?.firstName} {user?.lastName} | {user?.mazhab} | {`Тариф: ${user?.paid ? 'Премиум' : 'Free'}`}
                                </Typography>
                            }
                            {
                                !user?._id &&
                                authPages.map((page, index) => (
                                    <Button
                                        component={RouterLink}
                                        to={page.path}
                                        key={page.name + index}
                                        onClick={handleCloseNavMenu}
                                        sx={{ my: 2, color: 'white', display: 'block' }}
                                    >
                                        {page.name}
                                    </Button>
                                ))
                            }
                        </Box>

                        {/* Avatar menu logout */}
                        <Box sx={{ flexGrow: 0 }}>
                            {
                                user?._id &&
                                <Tooltip title="Open settings">
                                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                        <Avatar alt={user?.firstName} src="/static/images/avatar/2.jpg" />
                                    </IconButton>
                                </Tooltip>
                            }
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={() => setAnchorElUser(null)}
                            >
                                {settings.map((setting) => (
                                    <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                        <Typography textAlign="center">{setting}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
            >
                {drawer}
            </Drawer>
        </>
    );
}

export default ResponsiveAppBar;
